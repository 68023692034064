import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'

enum DatePickerType {
  Date,
  DateTime,
  Time,
}

export default class extends Controller<HTMLInputElement> {
  instance?: flatpickr.Instance

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _reset(): void {
    if (this.instance) {
      this.instance.destroy()
      delete this.instance
    }
  }

  private _build(): void {
    const type =
      this.element.matches('[type="datetime-local"]') ?
        DatePickerType.DateTime :
      this.element.matches('[type="time"]') ?
        DatePickerType.Time :
      this.element.matches('[type="date"]') ?
        DatePickerType.Date :
      DatePickerType.Date

    this.instance = flatpickr(this.element, {
      altInput: true,
      defaultDate: this.element.value,
      enableTime: type == DatePickerType.DateTime || type == DatePickerType.Time,
      noCalendar: type == DatePickerType.Time,
      time_24hr: true,
      minTime: '00:00',
      maxTime: '23:59',
      dateFormat:
        type == DatePickerType.Date ?
          'Y-m-d' :
        type == DatePickerType.DateTime ?
          'Y-m-d H:i' :
        'H:i',
      altFormat:
        type == DatePickerType.Date ?
          'd.m.Y' :
        type == DatePickerType.DateTime ?
          'd.m.Y H:i' :
        'H:i',
    })
  }
}
