import { Popup, PopupProps } from './popup_typescript/classes/Popup'

import './popup_sass/modal.css.sass'

interface ModalProps extends PopupProps {
  shifterColor?: string
  shifterOn?: boolean
  shifterCompensationSelector?: string
  shifterIgnoreCompensationSelector?: string
}

export class Modal extends Popup {
  private _shifter?: HTMLElement

  private readonly _shifterSelectors = {
    shifterSelector: this._props.shifterCompensationSelector ? this._props.shifterCompensationSelector : '[data-modal-shifter]',
    shifterIgnoreSelector: this._props.shifterIgnoreCompensationSelector ? this._props.shifterIgnoreCompensationSelector : '[data-modal-shifter-ignore]'
  }

  private _shiftData?: {
    initialX: number
    initialY: number
    initialTransformX: number
    initialTransformY: number
    x: number
    y: number
  }

  constructor(protected _props: ModalProps) {
    super(_props)

    if (this._props.shifterColor) {
      this._parsedTemplate.style.setProperty('--modal-shifter-color', this._props.shifterColor)
    }

    if (!this._props.shifterOn || this._props.shifterCompensationSelector) {
      this._parsedTemplate.querySelector('[data-modal-shifter]')!.remove()
    }

    if (this._props.shifterOn) {
      this._parsedTemplate.addEventListener('contextmenu', this._onContextMenuModalWithShifter)
      this._parsedTemplate.addEventListener('pointerdown', this._onPointerDownModalWithShifter)
    }
  }

  private _findShifterWithEvent(event: Event): HTMLElement | null {
    const closestShifterRelatedElement = (event.target as HTMLElement).closest(`${this._shifterSelectors.shifterSelector}, ${this._shifterSelectors.shifterIgnoreSelector}`)
    if (closestShifterRelatedElement && closestShifterRelatedElement.matches(this._shifterSelectors.shifterSelector)) {
      return closestShifterRelatedElement as HTMLElement
    } else {
      return null
    }
  }

  private _onContextMenuModalWithShifter = (event: MouseEvent) => {
    if (this._findShifterWithEvent(event)) {
      event.preventDefault()
    }
  }

  private _onPointerDownModalWithShifter = (event: PointerEvent) => {
    this.focus()

    const maybeShifter: HTMLElement | null = this._findShifterWithEvent(event)
    if (maybeShifter) {
      this._shifter = maybeShifter
      this._onPointerDownShifter(event)
    }
  }

  private _onPointerDownShifter = (event: PointerEvent): void => {
    this._shifter!.addEventListener('pointerup', this._onPointerUpShifter)
    this._shifter!.addEventListener('pointermove', this._onPointerMoveShifter)
    this._shifter!.setPointerCapture(event.pointerId)
    this._parsedTemplate.classList.add('shifting')

    const modalTransformX: string = this._parsedTemplate.style.getPropertyValue('--js-modal-transform-x')
    const modalTransformY: string = this._parsedTemplate.style.getPropertyValue('--js-modal-transform-y')

    let initialModalTransformX: number = modalTransformX ? parseFloat(modalTransformX): 0
    let initialModalTransformY: number = modalTransformY ? parseFloat(modalTransformY) : 0

    // Same formula also in engines/hulk_core/app/javascript/ui_components/helper_components/popup/popup_sass/modal.css.sass
    // as CSS
    const percent50X: number = this._parsedTemplate.clientWidth / 2
    const percent50Y: number = this._parsedTemplate.clientHeight / 2
    const vw50: number = document.documentElement.clientWidth / 2
    const vh50: number = document.documentElement.clientHeight / 2

    initialModalTransformX = Math.max(Math.min(initialModalTransformX, vw50 - percent50X), percent50X - vw50)
    initialModalTransformY = Math.max(Math.min(initialModalTransformY, vh50 - percent50Y), percent50Y - vh50)

    this._shiftData = {
      initialX: event.clientX,
      initialY: event.clientY,
      initialTransformX: initialModalTransformX,
      initialTransformY: initialModalTransformY,
      x: event.clientX,
      y: event.clientY
    }
  }

  private _onPointerUpShifter = (event: PointerEvent): void => {
    this._shifter!.removeEventListener('pointerup', this._onPointerUpShifter)
    this._shifter!.removeEventListener('pointermove', this._onPointerMoveShifter)
    this._shifter!.releasePointerCapture(event.pointerId)
    this._parsedTemplate.classList.remove('shifting')
    delete this._shifter
    delete this._shiftData
  }

  private _onPointerMoveShifter = (event: PointerEvent): void => {
    this._shiftData!.x = event.clientX
    this._shiftData!.y = event.clientY

    this._parsedTemplate.style.setProperty('--js-modal-transform-x', `${this._shiftData!.x - this._shiftData!.initialX + this._shiftData!.initialTransformX}px`)
    this._parsedTemplate.style.setProperty('--js-modal-transform-y', `${this._shiftData!.y - this._shiftData!.initialY + this._shiftData!.initialTransformY}px`)
  }

  protected _createTemplate(): string {
    return `
      <div class="modal-outest-element">
        <div class="modal-shifter" data-modal-shifter></div>
        <div data-popup-child-wrapper></div>
      </div>
    `
  }

  public close(): boolean {
    if (super.close()) {
      return true
    }

    this._parsedTemplate.classList.remove('shifting')

    return false
  }

  public destroy(): void {
    super.destroy()

    this._parsedTemplate.classList.remove('shifting')
  }
}
