import { Controller } from 'stimulus'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

interface MapStorage {
  [id: string]: {
    lat?: number
    lng?: number
  }
}

interface OfferCategory {
  name: string // "Refuelling"
}

interface Offer {
  name: string // "ILD-7f668b77a"
  discount_type?: 'Percent' | 'Absolute' | 'Percent Points' | null
  discount_value?: number | null // 5
  earn_coins?: number | null // 22
  min_cart_value?: string | null // 70
  max_cashback_value?: string | null // 0
  category?: OfferCategory
}

interface Marker {
  id: string
  marker: L.Marker
}

interface MarkerValue {
  id: string
  lat: number
  lng: number
  offers?: Offer[]
}

const DEFAULT_LAT_LNG = Object.freeze({
  lat: 48.137218,
  lng: 11.575425
})

const MAP_SESSION_KEY = 'map_storage_key'

const mapStorageJSON = sessionStorage.getItem(MAP_SESSION_KEY)
const mapStorage: MapStorage = mapStorageJSON ? JSON.parse(mapStorageJSON) : {}
const updateMapStorage = () => sessionStorage.setItem(MAP_SESSION_KEY, JSON.stringify(mapStorage))

export default class extends Controller<HTMLElement> {
  map?: L.Map

  static values = { lat: Number, lng: Number, id: String, markersJson: String }

  latValue?: number
  hasLatValue!: boolean

  lngValue?: number
  hasLngValue!: boolean

  idValue?: string
  hasIdValue!: boolean

  markersJsonValue?: string
  hasMarkersJsonValue!: boolean

  private _markers: Marker[] = []

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _build(): void {
    this.map = L.map(this.element as HTMLElement).setView([this.latValue || this._getFromStorage('lat') || DEFAULT_LAT_LNG.lat, this.lngValue || this._getFromStorage('lng') || DEFAULT_LAT_LNG.lng], 13)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '<a href="https://www.openstreetmap.org/copyright">&copy; OpenStreetMap contributors</a>'
    }).addTo(this.map);

    const center = this.map.getCenter()
    this._setToStorage('lat', center.lat)
    this._setToStorage('lng', center.lng)
    updateMapStorage()

    if (this.hasMarkersJsonValue) {
      const markersJSON = JSON.parse(this.markersJsonValue!) as MarkerValue[]
      for (const markerValue of markersJSON) {
        const marker = L.marker([markerValue.lat, markerValue.lng], {
          icon: L.divIcon({
            className: 'custom-div-icon',
            html: '<div style="background-color:#4838cc;" class="marker-pin"></div><i class="fa-solid fa-gas-pump" style="transform: scale(0.7) translateX(1px);">',
            iconSize: [30, 42],
            iconAnchor: [15, 42]
          }),
          zIndexOffset: 0
        }).addTo(this.map)

        if (markerValue.offers?.length && markerValue.offers[0].discount_value != null) {
          const firstOffer = markerValue.offers[0]
          const discountTypeSymbol = firstOffer.discount_type == 'Absolute' ? '€' : '%'

          const popup = document.createElement('div')
          popup.innerText = `${firstOffer.discount_value!.toPrecision(2)}${discountTypeSymbol}`
          popup.style.textAlign = 'center'
          marker.bindPopup(popup, {
            offset: L.point(0, -35),
            closeButton: false,
          })
        }

        marker.on('click', _ => {
          this.highlightMarker(markerValue.id)
        })

        this._markers.push({
          id: markerValue.id,
          marker: marker,
        })
      }

      if (markersJSON.length > 0) {
        this.highlightMarker(markersJSON[0].id)
      }
    }
  }

  private _reset(): void {
    if (this.map) {
      this.map.remove()
      delete this.map
    }

    this._markers.length = 0
  }

  private _setToStorage<K extends keyof MapStorage[string]>(key: K, value: MapStorage[string][K]): void {
    if (!this.hasIdValue) return;

    if (!mapStorage[this.idValue!]) {
      mapStorage[this.idValue!] = {}
    }

    mapStorage[this.idValue!][key] = value
  }

  private _getFromStorage<K extends keyof MapStorage[string]>(key: K): MapStorage[string][K] | undefined {
    if (!this.hasIdValue) return;
    return mapStorage[this.idValue!]?.[key]
  }

  // private _setAndUpdateToStorage<K extends keyof MapStorage[string]>(key: K, value: MapStorage[string][K]): void {
  //   this._setToStorage(key, value)
  //   updateMapStorage()
  // }

  public highlightMarker(id: string): void {
    for (const marker of this._markers) {
      const element = marker.marker.getElement()
      if (marker.id == id) {
        marker.marker.setZIndexOffset(1)
        // @ts-ignore
        marker.marker._bringToFront()
        marker.marker.openPopup()
        this.map?.panTo(marker.marker.getLatLng())
        element?.classList.add('custom-marker-active')
      } else {
        marker.marker.setZIndexOffset(0)
        marker.marker.closePopup()
        element?.classList.remove('custom-marker-active')
      }
    }
  }
}
