// Used for modals and all things who must lock the scroll position of the body
// Mulitple locks supported without wierd side effects

// Examples:

// React
// import { BodyLocker } from '../helpers/BodyLocker'
// class Modal extends React.Component {
//   componentDidMount() {
//     BodyLocker.lock(this)
//   }

//   componentWillUnmount() {
//     BodyLocker.unlock(this)
//   }
// }

// Stimulus
// const { BodyLocker } = require('../helpers/BodyLocker')
// export default class extends Controller {
//   connect() {
//     BodyLocker.lock(this)
//   }

//   disconnect() {
//     BodyLocker.unlock(this)
//   }
// }

// You can use every object type as a key but i prefere the instance who is actually locking the body to better understand the code
// (The key is the first param of the lock and unlock method)

// If multiple methods are locking the body it is not a problem if the same key is used

// BodyLocker.lock(this)
// BodyLocker.lock(this)
// BodyLocker.lock(this)

// is the same as

// BodyLocker.lock(this)

// alone

// Same rule for BodyLocker.unlock(this)

// If you unlock with a key you did not used to lock is also not a problem
// The unlock key will be ignored

// Awesome body lock tutorial:
// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/

export class BodyLocker {
  private static _lockKeys: any[] = []
  private static _lastScrollY: number = 0

  private static _onLock = (): void => {
    // Save the scroll position
    BodyLocker._lastScrollY = window.scrollY
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
    // Add styles to body to fix its position
    document.body.style.position = 'fixed'
    document.body.style.left = '0'
    document.body.style.right = '0'
    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${BodyLocker._lastScrollY}px`
  }

  private static _onUnlock = (): void => {
    // Remove tweaks for scrollbar
    document.body.style.removeProperty('padding-right')
    // Remove styles from body to unfix position
    document.body.style.removeProperty('position')
    document.body.style.removeProperty('left')
    document.body.style.removeProperty('right')
    // Remove the negative top inline style from body
    document.body.style.removeProperty('top')
    // Overwrite smooth scrolling from bootstrap to restore the scroll position without annoying animation
    document.documentElement.style.setProperty('scroll-behavior', 'revert')
    // Restore the scroll position of the body before it got locked
    window.scrollTo({ left: 0, top: BodyLocker._lastScrollY })
    // Restore the bootstrap scroll-behavior property since the scrollTo method is already called
    document.documentElement.style.removeProperty('scroll-behavior')
  }

  public static lock = (key: any): void => {
    if (BodyLocker._lockKeys.indexOf(key) > -1) {
      return
    }

    BodyLocker._lockKeys.push(key)

    if (BodyLocker._lockKeys.length == 1) {
      BodyLocker._onLock()
    }
  }

  public static unlock = (key: any): void => {
    if (BodyLocker.isUnlocked) {
      return
    }

    let keyIndex: number = BodyLocker._lockKeys.indexOf(key)
    if (keyIndex == -1) {
      return
    }

    BodyLocker._lockKeys.splice(keyIndex, 1)

    if (BodyLocker.isUnlocked) {
      BodyLocker._onUnlock()
    }
  }

  public static get isUnlocked() {
    return !BodyLocker._lockKeys.length
  }
}
