// ripple effect script. use data-ripple to enable ripple effect on an element
// inspired by https://codepen.io/BretCameron/pen/mdPMVaW

const style = document.createElement('style')
style.innerHTML = `
  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 1200ms ease-out;
    background-color: rgba(0, 0, 0, 0.07);
  }

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`
document.head.appendChild(style)

document.addEventListener('pointerdown', event => {
  const rippleTarget = (event.target as HTMLElement).closest('[data-ripple]') as HTMLElement
  if (!rippleTarget) return

  const ripple = document.createElement('span')
  const diameter = Math.max(rippleTarget.clientWidth, rippleTarget.clientHeight)
  const radius = diameter / 2
  const rect = rippleTarget.getBoundingClientRect()

  ripple.style.width = ripple.style.height = `${diameter}px`
  ripple.style.left = `${event.clientX - rect.left - radius}px`
  ripple.style.top = `${event.clientY - rect.top - radius}px`
  ripple.classList.add('ripple')

  rippleTarget.appendChild(ripple)

  window.setTimeout(() => ripple.remove(), parseFloat(window.getComputedStyle(ripple).animationDuration) * 1000)
})

// Fix if the ripple is cached by Turbo
document.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('turbo:load', () => {
    for (const ripple of document.querySelectorAll('span.ripple')) {
      ripple.remove()
    }
  })
})
