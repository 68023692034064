// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import AOS from 'aos'
import ahoy from 'ahoy.js'
import 'aos/dist/aos.css'
import './controllers/public_stimulus'
import '../sass/public.sass'
import './support/ripple'

// Turbo
Turbo.start()
Turbo.setProgressBarDelay(0)

// Tracking
ahoy.configure({
  urlPrefix: "",
  visitsUrl: "/v",
  eventsUrl: "/e",
  page: null,
  platform: "Web",
  useBeacon: true,
  startOnReady: false,
  trackVisits: true,
  cookies: false,
  cookieDomain: null,
  headers: {},
  visitParams: {},
  withCredentials: false,
  visitDuration: 4 * 60, // 4 hours
  visitorDuration: 2 * 365 * 24 * 60 // 2 years
});

ahoy.start()

// Scroll Tracking
{
  let highestScroll = 0
  window.addEventListener(
    'scroll',
    _ => highestScroll = Math.max(highestScroll, document.documentElement.scrollTop),
    { passive: true }
  )

  const trackScroll = () => {
    ahoy.track('Site Scroll Percent', {
      nowPercent: Math.min(
        (document.documentElement.scrollTop + window.innerHeight) / document.documentElement.scrollHeight * 100,
        100
      ),
      maxPercent: Math.min(
        (highestScroll + window.innerHeight) / document.documentElement.scrollHeight * 100,
        100
      ),
      sitePathname: window.location.pathname,
    })
  }

  document.addEventListener('turbo:visit', _ => {
    trackScroll()

    highestScroll = document.documentElement.scrollTop
  })

  window.addEventListener('beforeunload', _ => trackScroll());
}

// Visit Duration Tracking
{
  let pageVisitTimeMilliseconds = Date.now()

  const trackVisitDuration = () => {
    ahoy.track('Site Visit Duration', {
      durationInSeconds: (Date.now() - pageVisitTimeMilliseconds) * 0.001,
      sitePathname: window.location.pathname,
    })
  }

  document.addEventListener('turbo:visit', _ => {
    trackVisitDuration()
    pageVisitTimeMilliseconds = Date.now()
  })

  window.addEventListener('beforeunload', _ => trackVisitDuration());
}

// Animate on Scroll (AOS) initialization
document.addEventListener('DOMContentLoaded', _ => {
  AOS.init()
  document.addEventListener('turbo:load', _ => {
    AOS.refreshHard()
  })
})
