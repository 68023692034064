import { Controller } from 'stimulus'
import MapController from './map_controller'

export default class extends Controller<HTMLElement> {
  static values = { query: String, markerId: String }

  queryValue!: string
  markerIdValue!: string

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _build(): void {
    this.element.addEventListener('pointerenter', this._onPointerEnter)
  }

  private _reset(): void {
    this.element.removeEventListener('pointerenter', this._onPointerEnter)
  }

  private _onPointerEnter = (event: PointerEvent): void => {
    (this.application.getControllerForElementAndIdentifier(document.querySelector(this.queryValue) as HTMLElement, 'public--map') as MapController).highlightMarker(this.markerIdValue)
  }
}
