import { Controller } from 'stimulus'
import { Swiper, Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'

export default class extends Controller {
  static values = { delay: String, transitionDuration: String, showDots: String }

  delayValue?: string
  transitionDurationValue?: string
  showDotsValue?: string

  swiper?: Swiper

  connect(): void {
    this._reset()
    this._mount()
  }

  disconnect(): void {
    this._reset()
  }

  private _reset(): void {
    this.element.removeEventListener('pointerenter', this._onPointerEnterSection, false)
    this.element.removeEventListener('pointerleave', this._onPointerLeaveSection, false)

    if (this.swiper) {
      this.swiper.destroy()
      this.swiper = undefined
    }
  }

  private _mount(): void {
    this.element.addEventListener('pointerenter', this._onPointerEnterSection, false)
    this.element.addEventListener('pointerleave', this._onPointerLeaveSection, false)

    const swiperElement = this.element.querySelector('.swiper') as HTMLElement | null

    if (!swiperElement) return

    // @ts-ignore
    const speed = parseInt(this.transitionDurationValue)

    // @ts-ignore
    const delay = parseInt(this.delayValue)

    this.swiper = new Swiper(swiperElement, {
      speed: isNaN(speed) ? 3000 : speed,
      allowTouchMove: false,
      loop: true,
      autoplay: {
        delay: isNaN(delay) ? 3000 : delay,
      },
      pagination: this.showDotsValue?.toLowerCase() === 'true' ? {
        el: '.swiper-pagination',
        clickable: true,
      } : false,
      modules: [Autoplay, Pagination],
    })
  }

  private _onPointerEnterSection = (): void => {
    this.swiper?.autoplay.stop()
  }

  private _onPointerLeaveSection = (): void => {
    this.swiper?.autoplay.start()
  }
}
