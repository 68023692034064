import { Controller } from 'stimulus'

interface Subject {
  name?: string
  subject_name?: string
  brand?: string
  address_line1?: string
  city?: string
  postal_code?: string
  country?: string
  url?: string
  user_url?: string
}

type SearchResults = Subject[]

export default class extends Controller {
  static values = { url: String, placeholder: String }

  urlValue!: string
  placeholderValue!: string

  private _abortController?: AbortController
  private _elements?: {
    input: HTMLInputElement
    results: HTMLDivElement
  }
  private _previousQuery: string = ''
  private _fetchResultsTimeoutId?: number

  connect(): void {
    this._reset()
    this._buildInput()
  }

  disconnect(): void {
    this._reset()
  }

  private get _query(): string {
    return this._elements?.input.value ?? ''
  }

  private _buildInput(): void {
    this.element.innerHTML = `
      <label>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          style="width: 20px; height: 20px; color: var(--primary-color); fill: currentColor;">
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path>
        </svg>
        <input
          type="text"
          placeholder="${this.placeholderValue || 'Suchen...'}">
      </label>
      <div name="pg-search-input-results"></div>
      <div name="pg-search-input-results-background"></div>
    `

    this._elements = {
      input: this.element.querySelector('input') as HTMLInputElement,
      results: this.element.querySelector('div[name="pg-search-input-results"]') as HTMLDivElement
    }

    this._elements.input.addEventListener('input', this._onInput)
  }

  private _reset(): void {
    if (this._elements) {
      this._elements.input.removeEventListener('input', this._onInput)
      this._elements = undefined
    }

    this.element.innerHTML = ''
    this._resetQuery()
  }

  private _resetQuery(): void {
    this._previousQuery = ''
    this._stopFetchResultsTimeout()
    this._abortPreviousFetchRequest()

    if (this._elements) {
      if (this._elements.input.value.trim() != '') {
        this._elements.input.value = ''
      }

      this._elements.results.innerHTML = ''
    }

    this.element.classList.remove('pg-search-input-has-results')
  }

  private _stopFetchResultsTimeout(): void {
    if (this._fetchResultsTimeoutId != undefined) {
      window.clearTimeout(this._fetchResultsTimeoutId)
      this._fetchResultsTimeoutId = undefined
    }
  }

  private _fetchResults(): void {
    const trimmedQuery = this._query.trim()

    if (trimmedQuery == '') {
      this._resetQuery()
      return
    }

    if (trimmedQuery == this._previousQuery) return

    this._previousQuery = trimmedQuery

    const url = new URL(this.urlValue, window.location.origin)
    url.searchParams.append('query', trimmedQuery)

    this._abortPreviousFetchRequest()

    this._abortController = new AbortController()

    // toString because the typescript types are wrong inside webpacker
    fetch(url.toString(), { signal: this._abortController.signal })
      .then(response => response.json())
      .then((data: SearchResults) => {
        this._abortController = undefined
        this._renderResults(data)
      })
      .catch((error: any) => {
        this._abortController = undefined
      })
  }

  private _abortPreviousFetchRequest(): void {
    if (this._abortController) {
      this._abortController.abort()
      this._abortController = undefined
    }
  }

  private _onInput = (): void => {
    this._stopFetchResultsTimeout()
    this._fetchResultsTimeoutId = window.setTimeout(() => {
      this._fetchResultsTimeoutId = undefined
      this._fetchResults()
    }, 200)
  }

  private _renderResults(results: SearchResults): void {
    if (!this._elements) return

    const html = results.map(subject => {
      let itemChildren = ''

      if (subject.subject_name) {
        itemChildren += `<p><strong>${subject.subject_name}</strong></p>`
      }

      if (subject.brand) {
        itemChildren += `<p>${subject.brand}</p>`
      }

      if (subject.address_line1) {
        itemChildren += `<p>${subject.address_line1}</p>`
      }

      if (itemChildren) {
        if (subject.user_url) {
          return `
            <a
              class="pg-search-input-result"
              href=${subject.user_url}>
              ${itemChildren}
            </a>
          `
        } else {
          return `
            <div class="pg-search-input-result">
              ${itemChildren}
            </div>
          `
        }
      }

      return ''
    }).join('')

    this._elements.results.innerHTML =
      html
        ? html
        : '<div class="pg-search-input-result pg-search-input-no-result"><p>Keine Ergebnisse</p></div>'

    this.element.classList.add('pg-search-input-has-results')
  }
}
