import { Controller } from 'stimulus'

interface RelativeMenuComponents {
  contentWrapper: HTMLDivElement
  content: HTMLDivElement
  button: HTMLButtonElement
}

interface OpenRelativeMenuIds {
  [s: string]: boolean
}

const OPEN_RELATIVE_MENU_SESSION_KEY = 'open_relative_menu_ids'
const openRelativeMenuIdsJSON = window.sessionStorage.getItem(OPEN_RELATIVE_MENU_SESSION_KEY)

const openRelativeMenuIds: OpenRelativeMenuIds = openRelativeMenuIdsJSON ? JSON.parse(openRelativeMenuIdsJSON) : {}
const updateSessionStorage = (): void => window.sessionStorage.setItem(OPEN_RELATIVE_MENU_SESSION_KEY, JSON.stringify(openRelativeMenuIds))

export default class extends Controller {
  static targets = [ 'template' ]
  static values = { id: String }

  templateTarget!: HTMLTemplateElement

  idValue?: string
  hasIdValue!: boolean

  private _components?: RelativeMenuComponents

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _build(): void {
    this._components = {
      contentWrapper: document.createElement('div'),
      content: document.createElement('div'),
      button: document.createElement('button'),
    }

    this._components.contentWrapper.classList.add('vw-relative-menu-content-wrapper')
    this._components.content.classList.add('vw-relative-menu-content')
    this._components.button.classList.add('vw-relative-menu-button')

    this._components.button.addEventListener('click', this._toggleMenu)

    this._components.content.appendChild(this.templateTarget.content.cloneNode(true))
    this._components.contentWrapper.appendChild(this._components.content)

    this.element.appendChild(this._components.contentWrapper)
    this.element.appendChild(this._components.button)

    this.element.classList.add('vw-relative-menu')

    if (this.hasIdValue && openRelativeMenuIds[this.idValue!]) {
      this.element.classList.add('open')
    }
  }

  private _reset(): void {
    if (this._components) {
      this._components.button.removeEventListener('click', this._toggleMenu)
      this._components.contentWrapper.remove()
      this._components.button.remove()
      delete this._components
    }
    this.element.classList.remove('vw-relative-menu', 'open')
  }

  private _toggleMenu = (): void => {
    if (this.element.classList.contains('open')) {
      this.element.classList.remove('open')

      if (this.hasIdValue) {
        delete openRelativeMenuIds[this.idValue!]
        updateSessionStorage()
      }
    } else {
      this.element.classList.add('open')

      if (this.hasIdValue) {
        openRelativeMenuIds[this.idValue!] = true
        updateSessionStorage()
      }
    }
  }
}
