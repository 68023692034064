import { Application } from "@hotwired/stimulus"
// @ts-ignore
import { context as jsControllersContext } from './public/**/*_controller.js';
// @ts-ignore
import { context as tsControllersContext } from './public/**/*_controller.ts';
import { identifierForContextKey } from 'stimulus/webpack-helpers'

const jsControllers = Object.keys(jsControllersContext).map((filename) => ({
  identifier: identifierForContextKey(filename),
  controllerConstructor: jsControllersContext[filename]
}))

const tsControllers = Object.keys(tsControllersContext).map((filename) => ({
  identifier: identifierForContextKey(filename),
  controllerConstructor: tsControllersContext[filename]
}))

const application = Application.start()

// @ts-ignore
application.load([...jsControllers, ...tsControllers])
