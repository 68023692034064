import { Controller } from 'stimulus'
import { Modal } from '../../helpers/popup/Modal'

export default class extends Controller {
  static targets = ['template']

  templateTarget!: HTMLTemplateElement

  private modal?: Modal

  connect(): void {
    this.close()

    const child = this.templateTarget.content.cloneNode(true).firstChild as HTMLElement
    child.addEventListener('click', this._onClickModal, false)

    this.modal = new Modal({
      child: child,
      // shifterOn: true,
      // shifterCompensationSelector: '[data-modal-shifter]',
      // shifterIgnoreCompensationSelector: '[data-modal-shifter-ignore]',
    })
  }

  disconnect(): void {
    this.close()
  }

  private _onClickModal = (event: MouseEvent): void => {
    if (event.target instanceof HTMLElement && event.target.closest('[data-modal-template-close]')) {
      this.close()
    }
  }

  public open(): void {
    this.modal?.open()
  }

  public close(): void {
    this.modal?.close()
  }
}
