import { Controller } from 'stimulus'

const ERROR_INPUT_CLASSES = Object.freeze(['border-red-500'])
const REQUIRED_FORM_ELEMENT_SELECTOR = 'input[required], textarea[required], select[required]'

type FormElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

// class RsFormControllerParamStorage {
//   private static _data?: Partial<Record<string, string>>
//   private static readonly _storageKey = 'rs-form-controller-param-storage'

//   private static _read(): void {
//     // @ts-ignore
//     this._data = JSON.parse(localStorage.getItem(this._storageKey)) ?? {}
//   }

//   public static get(): string | undefined {
//     this._read()
//     return this._data![window.location.pathname]
//   }

//   public static set(search: string): void {
//     this._read()
//     this._data![window.location.pathname] = search
//     localStorage.setItem(this._storageKey, JSON.stringify(this._data))
//   }
// }

export default class extends Controller<HTMLFormElement> {
  static values = { keepParams: String, shouldUseParams: Boolean }

  keepParamsValue!: String
  hasKeepParamsValue!: boolean

  shouldUseParamsValue!: boolean

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _reset(): void {
    this.element.removeEventListener('change', this._onChange)
    this.element.removeEventListener('input', this._onInput)
    this.element.removeEventListener('click', this._onClick)
    this.element.removeEventListener('submit', this._onSubmit)
  }

  private _build(): void {
    this.element.addEventListener('change', this._onChange)
    this.element.addEventListener('input', this._onInput)
    this.element.addEventListener('click', this._onClick)
    this.element.addEventListener('submit', this._onSubmit)
    this._useParamsValue()
  }

  private _useParamsValue(): void {
    if (!this.shouldUseParamsValue) return

    // Inputs
    const inputs = [...this.element.querySelectorAll<FormElement>('input:not([data-controller~="public--date-picker"] + input[type="text"], [type="button"], [type="submit"], [type="reset"]), select, textarea')]

    // Later for removing search params
    const removeSearchQueryNames = new Set<string>()
    for (const input of inputs) {
      removeSearchQueryNames.add(input.name)
    }
    if (this.hasKeepParamsValue) {
      for (const keepParam of this.keepParamsValue.split(',').map(param => param.trim())) {
        removeSearchQueryNames.delete(keepParam)
      }
    }

    // Set input values from old search params
    // const cachedSearchParams = new URLSearchParams(RsFormControllerParamStorage.get())
    // for (const [key, value] of cachedSearchParams) {
    //   const foundInput = inputs.find(input => input.name == key)
    //   if (foundInput) {
    //     foundInput.value = value
    //     this._hideInputIfHideIfSaved(foundInput)
    //   }
    // }

    // Set input values from new search params
    const searchParams = new URLSearchParams(window.location.search)
    // for (const [key, value] of searchParams) {
    //   const foundInput = inputs.find(input => input.name == key)
    //   if (foundInput) {
    //     foundInput.value = value
    //     this._hideInputIfHideIfSaved(foundInput)
    //   }
    // }

    // Update cached search params to newer values
    // const mergedSearchParamsTemplate: Record<string, string> = {}
    // for (const [key, value] of cachedSearchParams) {
    //   mergedSearchParamsTemplate[key] = value
    // }
    // for (const [key, value] of searchParams) {
    //   mergedSearchParamsTemplate[key] = value
    // }
    // const updatedSearchParams = new URLSearchParams(mergedSearchParamsTemplate)
    // updatedSearchParams.sort()
    // RsFormControllerParamStorage.set(`?${updatedSearchParams.toString()}`)

    // Remove search params that are now inside inputs
    const initialSearchParamString = searchParams.toString()
    for (const removeSearchQueryName of removeSearchQueryNames) {
      searchParams.delete(removeSearchQueryName)
    }
    const finalSearchParamString = searchParams.toString()
    if (initialSearchParamString != finalSearchParamString) {
      window.history.replaceState(null, '', `${window.location.pathname}${finalSearchParamString.length ? `?${finalSearchParamString}` : ''}`)
    }

    // Auto post param
    if (searchParams.get('auto-post') == 'true' && this._checkValidityAndUpdate()) {
      this.element.submit()
    }
  }

  // private _hideInputIfHideIfSaved(input: FormElement): void {
  //   if (!input.hidden && input.matches('[data-hide-if-saved]')) {
  //     input.hidden = true
  //     input.parentElement!.style.display = 'none'
  //   }
  // }

  private _checkValidityAndUpdate(): boolean {
    for (const requiredFormElement of this.element.querySelectorAll<FormElement>(REQUIRED_FORM_ELEMENT_SELECTOR)) {
      if (!this._validateAndUpdateFormElement(requiredFormElement, true)) {
        return false
      }
    }

    return true
  }

  private _validateAndUpdateFormElement(formElement: FormElement, focus: boolean) {
    if (!(focus ? formElement.reportValidity() : formElement.checkValidity())) {
      formElement.classList.add(...ERROR_INPUT_CLASSES)
      return false
    } else if (formElement.matches('input[data-controller~="public--date-picker"]') && !formElement.value) {
      const visibleInput = formElement.nextElementSibling as HTMLInputElement
      visibleInput.classList.add(...ERROR_INPUT_CLASSES)
      if (focus) {
        visibleInput.click()
      }
      return false
    } else {
      formElement.classList.remove(...ERROR_INPUT_CLASSES)

      if (formElement.matches('input[data-controller~="public--date-picker"]')) {
        (formElement.nextElementSibling as HTMLInputElement).classList.remove(...ERROR_INPUT_CLASSES)
      }
    }

    return true
  }

  private _onChange = (e: Event): void => {
    if (!(e.target as HTMLElement | null)?.matches(REQUIRED_FORM_ELEMENT_SELECTOR)) return
    this._validateAndUpdateFormElement(e.target as FormElement, true)
  }

  private _onInput = (e: Event): void => {
    if (!(e.target as HTMLElement | null)?.matches(REQUIRED_FORM_ELEMENT_SELECTOR)) return
    this._validateAndUpdateFormElement(e.target as FormElement, false)
  }

  private _onClick = (e: MouseEvent) => {
    if ([...this.element.querySelectorAll<HTMLInputElement>('input[type="submit"]')].indexOf(e.target as any) == -1) return
    this._onSubmit(e)
  }

  private _onSubmit = (e: Event): void => {
    if (!this._checkValidityAndUpdate()) {
      e.preventDefault()
    }
  }
}
