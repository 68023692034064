import { Controller } from 'stimulus'

interface MobileNavComponents {
  background: HTMLDivElement
  mobileNav: HTMLDivElement
  root: HTMLDivElement
}

export default class extends Controller {
  static targets = [ 'button', 'template' ]
  static values = { media: String }

  buttonTarget!: HTMLElement
  templateTarget!: HTMLTemplateElement

  // 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  mediaValue?: string
  hasMediaValue!: boolean

  mobileNavComponents?: MobileNavComponents

  connect(): void {
    this._reset()
    this._build()
  }

  disconnect(): void {
    this._reset()
  }

  private _build(): void {
    this.mobileNavComponents = {
      root: document.createElement('div'),
      background: document.createElement('div'),
      mobileNav: document.createElement('div'),
    }

    this.mobileNavComponents.root.classList.add('vw-mobile-nav-root', this.hasMediaValue ? this.mediaValue! : 'md')
    this.mobileNavComponents.background.classList.add('vw-mobile-nav-background')
    this.mobileNavComponents.mobileNav.classList.add('vw-mobile-nav')

    this.mobileNavComponents.background.addEventListener('click', this._toggleMobileNav)
    this.mobileNavComponents.mobileNav.addEventListener('click', this._onClickNav)

    this.mobileNavComponents.mobileNav.appendChild(this.templateTarget.content.cloneNode(true))

    this.mobileNavComponents.root.appendChild(this.mobileNavComponents.background)
    this.mobileNavComponents.root.appendChild(this.mobileNavComponents.mobileNav)

    document.body.appendChild(this.mobileNavComponents.root)

    this.buttonTarget.addEventListener('click', this._toggleMobileNav)
  }

  private _reset(): void {
    this.buttonTarget.removeEventListener('click', this._toggleMobileNav)

    if (!this.mobileNavComponents) return

    this.mobileNavComponents.background.removeEventListener('click', this._toggleMobileNav)
    this.mobileNavComponents.mobileNav.removeEventListener('click', this._onClickNav)

    this.mobileNavComponents.root.remove()

    delete this.mobileNavComponents
  }

  private _toggleMobileNav = (): void => {
    if (this.mobileNavComponents!.root.classList.contains('open')) {
      this.mobileNavComponents!.root.classList.remove('open')
    } else {
      this.mobileNavComponents!.root.classList.add('open')
    }
  }

  private _onClickNav = (event: MouseEvent): void => {
    if ((event.target as HTMLElement).closest('a[href]')) {
      this._toggleMobileNav()
    }
  }
}
