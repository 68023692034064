export default class ArrayHelper {
  public static removeValue = <T>(array: T[], value: T):void => {
    let index: number = array.indexOf(value)
    if (index > -1) {
      array.splice(index, 1)
    }
  }

  public static addValue = <T>(array: T[], value: T):void => {
    ArrayHelper.removeValue(array, value)
    array.push(value)
  }

  public static removeMultipleValues = <T>(array: T[], ...values: T[]):void => {
    for (let value of values) {
      ArrayHelper.removeValue(array, value)
    }
  }

  public static addMultipleValues = <T>(array: T[], ...values: T[]):void => {
    for (let value of values) {
      ArrayHelper.addValue(array, value)
    }
  }

  public static createRemoveFunction = <T>(array: T[]):((...values: T[]) => void) => (...values: T[]):void => ArrayHelper.removeMultipleValues(array, ...values)

  public static createAddFunction = <T>(array: T[]):((...values: T[]) => void) => (...values: T[]):void => ArrayHelper.addMultipleValues(array, ...values)

  public static activateListenerFunctions = <T>(functionListenerArray: Array<(...params: any[]) => T>, ...params: any[]):void => {
    for (let functionListener of functionListenerArray) {
      functionListener(...params)
    }
  }

  public static createActivateListenerFunctionsFunction = <T>(functionListenerArray: Array<(...params: any[]) => T>):((...params: any[]) => void) => (...params: any[]):void => ArrayHelper.activateListenerFunctions(functionListenerArray, ...params)
}
