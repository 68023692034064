import { Controller } from 'stimulus'
import ahoy from 'ahoy.js'

/**
 * Handles Popup or Offcanvas component with contents, with backend
 * communication.
 */
export default class extends Controller<HTMLElement> {
  static values = {
    width: { type: String, default: '400px' },
    height: { type: String, default: '315px' },
    src: String,
    title: { type: String, default: '' },
    frameborder: { type: Number, default: 0 },
    allow: { type: String, default: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' },
    allowfullscreen: { type: Boolean, default: true },
  }

  widthValue!: string
  heightValue!: string
  srcValue?: string
  titleValue!: string
  frameborderValue!: number
  allowValue!: string
  allowfullscreenValue!: boolean

  connect(): void {
    this.element.addEventListener('click', this._replaceWithYtPlayer)

    this.element.style.width = this.widthValue
    // this.element.style.height = this.heightValue || '315px'
  }

  disconnect(): void {
    this.element.removeEventListener('click', this._replaceWithYtPlayer)
  }

  private _replaceWithYtPlayer = (): void => {
    ahoy.track('YouTube Video Played', {
      youTubeUrl: this.srcValue,
      sitePathname: window.location.pathname,
    })

    const iframe = document.createElement('iframe');

    iframe.setAttribute('width', this.widthValue)
    iframe.setAttribute('height', this.heightValue)
    iframe.setAttribute('src', this.srcValue || '')
    iframe.setAttribute('title', this.titleValue)
    iframe.setAttribute('frameborder', this.frameborderValue.toString())
    iframe.setAttribute('allow', this.allowValue)
    iframe.setAttribute('allowfullscreen', this.allowfullscreenValue.toString())

    this.element.replaceWith(iframe);
  }
}
