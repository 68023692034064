import { BodyLocker } from '../../../BodyLocker'
import ArrayHelper from '../../../ArrayHelper'

import '../../popup_sass/popup.css.sass'

export interface PopupProps {
  child: HTMLElement
  modalOff?: boolean
  closeFunction?: (event: MouseEvent) => void
}

export abstract class Popup {
  private static _popupTarget: HTMLDivElement = document.createElement('div')

  private static _openPopupInstances: Popup[] = []

  private static _setZIndexesInOpenInstances(): void {
    for (let index in this._openPopupInstances) {
      this._openPopupInstances[index]._fullyParsedTemplate.style.zIndex = index
    }
  }

  private static _resetPopupDomIfNewSiteDetected(): void {
    if (document.body.contains(Popup._popupTarget)) {
      return
    }

    for (let instance of [...Popup._openPopupInstances]) {
      instance.destroy()
    }
    Popup._popupTarget.innerHTML = ''

    document.body.appendChild(Popup._popupTarget)
  }

  public static staticConstructor(): void {
    Popup._popupTarget.classList.add('hulk-popup-target')
  }

  private _closeTimeoutId?: number
  private _openAnimationFrameId?: number

  private _fullyParsedTemplate!: HTMLElement

  protected _parsedTemplate!: HTMLElement

  constructor(protected _props: PopupProps) {
    this._parseTemplate()
  }

  private _parseTemplate(): void {
    let templateParser: HTMLDivElement = document.createElement('div')
    templateParser.innerHTML = this._createTemplate()

    this._parsedTemplate = templateParser.firstElementChild as HTMLElement

    if (this._parsedTemplate.matches('[data-popup-child-wrapper]')) {
      this._parsedTemplate.appendChild(this._props.child)
    } else {
      try {
        this._parsedTemplate.querySelector('[data-popup-child-wrapper]')!.appendChild(this._props.child)
      } catch (error) {
        console.error('Please set the "data-popup-child-wrapper" attribute in your template to specify the location of the popup child')
      }
    }

    this._fullyParsedTemplate = document.createElement('div')
    this._fullyParsedTemplate.classList.add('popup-portal')

    if (!this._props.modalOff) {
      this._fullyParsedTemplate.innerHTML = '<div class="popup-modal-background"></div>'
      ;(this._fullyParsedTemplate.querySelector('.popup-modal-background') as HTMLDivElement).addEventListener('click', this._props.closeFunction ? this._props.closeFunction : () => this.close())
    }

    this._fullyParsedTemplate.appendChild(this._parsedTemplate)
  }

  private _clearCloseTimeout(): void {
    if (this._closeTimeoutId) {
      window.clearTimeout(this._closeTimeoutId)
      delete this._closeTimeoutId
    }
  }

  private _clearOpenAnimationFrameRequest(): void {
    if (this._openAnimationFrameId) {
      window.cancelAnimationFrame(this._openAnimationFrameId)
      delete this._openAnimationFrameId
    }
  }

  /**
   * The template of the extending Class
   * This template must contain a element with the data-popup-child-wrapper attribute where the child is going
   */
  protected abstract _createTemplate(): string

  /**
   * @returns {boolean} canceled
   */
  public open(): boolean {
    if (this.isOpen()) {
      this.focus()
      return true
    }

    Popup._resetPopupDomIfNewSiteDetected()

    Popup._popupTarget.appendChild(this._fullyParsedTemplate)

    if (!this._props.modalOff) {
      BodyLocker.lock(this)
    }

    this._clearCloseTimeout()
    this._fullyParsedTemplate.classList.remove('closed')

    this._clearOpenAnimationFrameRequest()

    this._openAnimationFrameId = window.requestAnimationFrame(() => {
      delete this._openAnimationFrameId

      this._fullyParsedTemplate.classList.add('opened')
    })

    ArrayHelper.addValue(Popup._openPopupInstances, this)
    Popup._setZIndexesInOpenInstances()

    return false
  }

  /**
   * @returns {boolean} canceled
   */
  public close(): boolean {
    if (!this.isOpen()) {
      return true
    }

    this._clearOpenAnimationFrameRequest()

    this._fullyParsedTemplate.classList.remove('opened')
    this._fullyParsedTemplate.classList.add('closed')

    this._closeTimeoutId = window.setTimeout(() => {
      delete this._closeTimeoutId

      this._fullyParsedTemplate.remove()
      this._fullyParsedTemplate.classList.remove('closed')

      if (!this._props.modalOff) {
        BodyLocker.unlock(this)
      }
    }, parseFloat(window.getComputedStyle(Popup._popupTarget).getPropertyValue('--hulk-popup-transition-duration')) * 1000)

    ArrayHelper.removeValue(Popup._openPopupInstances, this)
    Popup._setZIndexesInOpenInstances()
    this._fullyParsedTemplate.style.removeProperty('z-index')

    return false
  }

  /**
   * @returns {boolean} canceled
   */
   public focus(): boolean {
    if (!this.isOpen() || Popup._openPopupInstances[Popup._openPopupInstances.length - 1] == this) {
      return true
    }

    ArrayHelper.addValue(Popup._openPopupInstances, this)
    Popup._setZIndexesInOpenInstances()

    return false
  }

  public destroy(): void {
    this._fullyParsedTemplate.remove()
    this._fullyParsedTemplate.classList.remove('opened')
    this._fullyParsedTemplate.classList.remove('closed')

    if (!this._props.modalOff) {
      BodyLocker.unlock(this)
    }

    this._clearCloseTimeout()

    this._clearOpenAnimationFrameRequest()

    ArrayHelper.removeValue(Popup._openPopupInstances, this)
    Popup._setZIndexesInOpenInstances()
    this._fullyParsedTemplate.style.removeProperty('z-index')
  }

  public isOpen(): boolean {
    return Popup._popupTarget.contains(this._fullyParsedTemplate) && !this._closeTimeoutId
  }
}

Popup.staticConstructor()
